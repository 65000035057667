import axios from 'axios';

let ambient = "dev";

let baseURL;

if(ambient === "dev"){
  baseURL = 'https://bcx-api-dev.be.xom.cloud/api/v1';
} else
{
  baseURL = 'https://bcx-api-acc.be.xom.cloud/api/v1';
}

if(ambient === "prod"){
  baseURL = 'https://api.carker.com/api/v1';
}

if(ambient === "preprod"){
  baseURL = 'https://bcx-api-preprod.be.xom.cloud/api/v1';
}

export default axios.create({
  baseURL: baseURL,
});